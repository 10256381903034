<template>
  <div v-if="item" class="price-wrapper">
    <v-select
      item-text="name"
      :return-object="true"
      hide-details=""
      v-model="typeChangePrice"
      placeholder="Change all price"
      :items="[
        { name: 'Multiply by', id: 1 },
        { name: 'Add to', id: 2 },
        { name: 'Set new price', id: 3 },
      ]"
    ></v-select>
    <div v-click-outside="onClickOutside" class="input-add-price d-flex" style="width:250px" v-if="typeChangePrice">
      <v-text-field
        style="border-radius:none !important;width:200px"
        v-model.number="value"
        type="number"
        placeholder=""
        class=""
      ></v-text-field>
      <v-btn class="mt-1" color="primary" @click="submit">Apply</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'price',
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      typeChangePrice: null,
      value: null,
    };
  },
  methods: {
    onClickOutside() {
      this.value = null;
      this.typeChangePrice = null;
    },
    submit() {
      if (this.value && typeof this.value === 'number') {
        let obj = {
          type: this.type,
          value: this.value,
          typeChangePrice: this.typeChangePrice,
        };
        console.log('obj', obj);
        this.$emit(`apply`, obj);
      }
    },
  },
};
</script>

<style lang="scss">
.input-add-price {
  .v-input__slot {
    border-radius: none !important;
  }
}
</style>
