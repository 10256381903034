import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/reviews/aliProducts/';

function getAliProductById(id, isUsZone) {
  return storeRequest.get(`/reviews/aliProductInfo/${id}?siteZone=${isUsZone >= 0 ? 'us' : 'com'}`);
}
function create(data) {
  return storeRequest.post(path, data);
}
function get() {
  return storeRequest.get(path);
}
function update(id, data) {
  return storeRequest.put(path + id, data);
}
function remove(id) {
  return storeRequest.delete(path + id);
}
export const apiAliProduct = {
  getAliProductById,
  create,
  get,
  update,
  remove,
};
